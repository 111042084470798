






import Vue from 'vue'
import StaffUserManager from '../../components/staffusermanager/StaffUserManager.vue'
export default Vue.extend({
    name: 'StaffUserManagerPage',
    components: {
        StaffUserManager
    }
})
