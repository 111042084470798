




























































































































































import auth from '@/mixins/auth';
import { Account } from '@/models/account';
import dateFormat from '@/utils/dateFormat';
import { PropType } from 'vue';
export default auth.extend({
  props: {
    filterUserType: {
      type: [] as PropType<Account[]>
    }
  },
  methods: {
    dateFormat,

    openDialog(user: Account) {
      this.$emit('openDialog', user, true);
    },

    selectedUser(user: Account) {
      this.$emit('selectedUser', user);
    },

    updateUserType(user: Account) {
      this.$emit('updateUserType', user);
    },

    getUserTypeText(userType: string) {
      if (userType == 'C') {
        return 'Customer';
      } else if (userType == 'A') {
        return 'Admin';
      } else if (userType == 'SA') {
        return 'Super Admin';
      } else {
        return 'Employee';
      }
    },

    getUserTypeColor(userType: string) {
      if (userType == 'C') {
        return 'primary';
      } else if (userType == 'A') {
        return 'red';
      } else if (userType == 'SA') {
        return 'warning';
      } else {
        return 'success';
      }
    }
  }
});
