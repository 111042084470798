
































































































































































































































































































import { Auth } from '@/models/auth';
import { ErrorManager } from '@/models/error';
import { FormRules } from '@/utils/formRules';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import dateFormat from '@/utils/dateFormat';
import { cloneDeep } from 'lodash';
import pdf from 'vue-pdf';
import { Address } from '@/models/address';
import { UserAddress } from '@/models/user_address';
import auth from '@/mixins/auth';
import FranchisePicker from '../stafffranchisemanager/FranchisePicker.vue';
import { Franchise } from '@/models/franchise';

export default auth.extend({
  name: 'StaffUserForm',

  props: {
    userProfile: {
      type: Boolean,
      default: () => true
    }
  },

  components: { pdf, FranchisePicker },

  mixins: [FormRules],

  data() {
    return {
      dialog: false,
      valid: false,
      fromDateMenu: false,
      loading: false,
      editForm: false,

      fromDateVal: new Date().toISOString().slice(0, 10) as
        | string
        | Date
        | undefined,
      displayImage: null as string | Blob | null,
      image: null as string | null,
      errorMessage: '',
      user: new Auth(),

      parishList: [
        {
          name: 'Kingston',
          value: 'KIN'
        },
        {
          name: 'St. James',
          value: 'MBJ'
        }
      ]
    };
  },

  computed: {
    ...mapState('account', ['account']),

    roleList() {
      const list = [
        { text: 'Customer', value: 'C' },
        { text: 'Admin', value: 'A' },
        { text: 'Employee', value: 'E' }
      ];

      if (this.isSuperAdmin) {
        list.push({ text: 'Super Admin', value: 'SA' });
        list.push({ text: 'Franchise Owner', value: 'FO' });
      }
      return list;
    },

    disableBtn(): boolean {
      return (
        this.valid &&
        this.user.password1 == this.user.password2 &&
        this.user.password1 != '' &&
        this.user.password2 != ''
      );
    },

    dateValue(): any {
      return this.fromDateVal;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      register: 'auth/register',
      updateUser: 'user/updateUser'
    }),
    dateFormat,

    open(user: Auth, editForm = false) {
      this.dialog = true;
      this.editForm = editForm;
      if (this.editForm) {
        this.user = cloneDeep(user);
        if (this.user.address == null) {
          this.user.address = new Address();
        }
        if (this.user.user_delivery_address == null) {
          this.user.user_delivery_address = new UserAddress();
        }
        this.image = this.user.verification_file!;
        this.fromDateVal = this.user.date_of_birth;
      }
    },

    close() {
      (this.$refs.form as any).resetValidation();
      this.user = new Auth();
      this.image = null;
      this.displayImage = null;
      this.dialog = false;
      this.errorMessage = '';
    },

    convertToFormData(user: Auth) {
      const formData = new FormData();

      if (this.displayImage && this.displayImage != null) {
        formData.append('verification_file', this.displayImage);
      }
      formData.append('first_name', user.first_name as string);
      formData.append('last_name', user.last_name as string);
      formData.append('date_of_birth', user.dob as any);
      formData.append('trn', (user.trn as any) ?? '');
      formData.append('role', user.role as string);
      formData.append('phone', user.phone as string);
      formData.append(
        'franchise',
        typeof user.franchise == 'number'
          ? user.franchise
          : ((user.franchise as Franchise).pk as any)
      );
      if (!this.editForm) {
        formData.append('email', user.email as string);
        formData.append('password1', user.password1 as string);
        formData.append('password2', user.password2 as string);
      }
      formData.append(
        'address.pick_up_parish',
        (user.address?.pick_up_parish as string) ?? ''
      );
      formData.append(
        'user_delivery_address.street_1',
        (user.user_delivery_address?.street_1 as string) ?? ''
      );
      formData.append(
        'user_delivery_address.street_2',
        (user.user_delivery_address?.street_2 as string) ?? ''
      );
      formData.append(
        'user_delivery_address.city',
        (user.user_delivery_address?.city as string) ?? ''
      );
      formData.append(
        'user_delivery_address.parish',
        (user.user_delivery_address?.parish as string) ?? ''
      );

      return formData;
    },

    addNewUser() {
      this.loading = true;
      this.user.dob = dateFormat(this.dateValue, 'YYYY-MM-DD', false);

      const newUserFormData = this.convertToFormData(this.user);

      this.register(newUserFormData)
        .then(() => {
          this.close();
          this.$emit('setSnackbar', this.user.email);
        })
        .catch((error: any) => {
          if (error.response.status == 400) {
            if (error.response.data.email) {
              this.errorMessage = error.response.data.email;
            } else {
              this.errorMessage = ErrorManager.extractApiError(error);
            }
          } else {
            this.errorMessage = ErrorManager.extractApiError(error);
          }
        })
        .finally(() => (this.loading = false));
    },

    editUser() {
      this.loading = true;
      this.user.dob = dateFormat(this.dateValue, 'YYYY-MM-DD', false);

      const newUserFormData = this.convertToFormData(this.user);

      this.updateUser({ pk: this.user.pk, data: newUserFormData })
        .then(() => {
          this.$emit('genUserAccount');
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
