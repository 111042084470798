






import StaffUserManagerPage from './StaffUserManager.vue';
import Vue from 'vue';
export default Vue.extend({
  components: {
    StaffUserManagerPage,
  },
});
