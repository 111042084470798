



















































import auth from '@/mixins/auth';
import { Account } from '@/models/account';
import { ErrorManager } from '@/models/error';
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';

export default auth.extend({
  data() {
    return {
      loading: false,
      dialog: false,
      errorMessage: '',
      localUser: new Account(),
    };
  },
  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser',
    }),

    open(user: Account) {
      this.dialog = true;
      this.localUser = cloneDeep(user);
    },
    close() {
      this.dialog = false;
    },

    updateUserRole() {
      this.loading = true;

      const payload = {
        role: this.localUser.role,
        phone: this.localUser.phone,
        trn: this.localUser.trn,
      };

      this.updateUser({ pk: this.localUser.pk, data: payload })
        .then(() => this.close())
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
});
