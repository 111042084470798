




























































































































































































































































































import auth from '@/mixins/auth';
import { Account, IAccountParams } from '@/models/account';
import { ErrorManager } from '@/models/error';
import { mapActions, mapGetters, mapState } from 'vuex';
import StaffUserForm from './StaffUserForm.vue';
import StaffUserManagerMobile from './StaffUserManagerMobile.vue';
import UserTypeDialog from './UserTypeDialog.vue';
import FranchiseFilter from '../stafffranchisemanager/FranchiseFilter.vue';
import mixins from '@/utils/mixins';
import Functions from '@/mixins/functions';

export default mixins(auth, Functions).extend({
  components: {
    UserTypeDialog,
    StaffUserForm,
    StaffUserManagerMobile,
    FranchiseFilter
  },
  name: 'StaffUserManager',
  data() {
    return {
      loading: false,
      dialog: false,
      deleteUserDialog: false,
      snackbar: false,

      errorMessage: '',
      params: { search: '', page: 1 } as IAccountParams,
      snackbarEmail: '',

      chosenUser: new Account(),
      userTypeActiveView: { value: null, text: 'All' } as number | any,

      franchiseActiveView: { value: null, text: 'All' } as number | any,

      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
        {
          text: 'TRN',
          value: 'trn'
        },
        {
          text: 'Verification File',
          value: 'verification_file'
        },
        {
          text: 'Type',
          value: 'role'
        },
        {
          text: 'D.O.B',
          value: 'date_of_birth'
        },
        {
          text: '',
          value: 'actions'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      numUsers: 'user/getUserCount',
      users: 'user/getUsers'
    }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    screenWidth() {
      return window.innerWidth > 600;
    },

    userHeaders(): any {
      if (this.isSuperAdmin) {
        this.headers.splice(8, 0, {
          text: 'Franchise',
          value: 'franchise'
        });
      }
      return this.headers;
    },

    userTypeViews(): any {
      const allViewsOption = [
        { text: 'All', value: null },
        { text: 'Customer', value: 'C', color: this.getUserTypeColor('C') },
        { text: 'Admin', value: 'A', color: this.getUserTypeColor('A') },
        { text: 'Employee', value: 'E', color: this.getUserTypeColor('E') },
        {
          text: 'Franchise Owner',
          value: 'FO',
          color: this.getUserTypeColor('FO')
        }
      ];

      if (this.isSuperAdmin) {
        allViewsOption.push({
          text: 'Super Admin',
          value: 'SA',
          color: this.getUserTypeColor('SA')
        });
      }

      return allViewsOption;
    }
  },

  watch: {
    'params.search'(search: string) {
      this.debounceFetchUsers({
        ...this.params,
        search: search,
        page: 1
      }).then(() => {
        this.params.page = 1;
      });
    },

    'params.page'(page: number) {
      this.onUpdatePage(page);
    },

    userTypeActiveView: {
      handler(type) {
        this.params.role = type.value;
        this.setUserData({ ...this.params, role: type.value }).then(() => {
          this.params.role = type.value;
        });
      }
    },

    franchiseActiveView: {
      handler(franchise) {
        this.setUserData({ ...this.params, franchise: franchise.value }).then(
          () => {
            this.params.franchise = franchise.value;
          }
        );
      }
    }
  },

  methods: {
    ...mapActions({
      deleteUser: 'user/deleteUser',
      fetchUsers: 'user/fetchUsers'
    }),

    clearAllErrors() {
      this.errorMessage = '';
    },

    openDialog(item = undefined) {
      if (item) {
        (this as any).$refs.userform.open(item, true);
      } else {
        (this as any).$refs.userform.open(item);
      }
    },

    onUpdatePage(newPage: number) {
      this.setUserData({
        ...this.params,
        page: newPage
      }).then(() => {
        this.params.page = newPage;
      });
    },

    async debounceFetchUsers(params: IAccountParams) {
      this.clearAllErrors();
      this.loading = true;

      return this.debounce(this.setUserData, 200)(params);
    },

    getUserTypeText(userType: string) {
      if (userType == 'C') {
        return 'Customer';
      } else if (userType == 'A') {
        return 'Admin';
      } else if (userType == 'SA') {
        return 'Super Admin';
      } else if (userType == 'FO') {
        return 'Franchise Owner';
      } else {
        return 'Employee';
      }
    },

    getUserTypeColor(userType: string) {
      if (userType == 'C') {
        return 'primary';
      } else if (userType == 'A') {
        return 'red';
      } else if (userType == 'SA') {
        return 'warning';
      } else if (userType == 'FO') {
        return 'success darken-2';
      } else {
        return 'success';
      }
    },

    selectedUser(item: Account) {
      this.chosenUser = item;
      this.deleteUserDialog = true;
    },

    updateUserType(user: Account) {
      this.dialog = true;
      (this as any).$refs.usertypedialog.open(user);
    },

    removeUser() {
      this.loading = true;

      this.deleteUser(this.chosenUser)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
          this.deleteUserDialog = false;
        });
    },

    setSnackbar(email: string) {
      this.setUserData();
      this.snackbar = true;
      this.snackbarEmail = email;
    },

    setUserData(params?: IAccountParams) {
      this.loading = true;

      return this.fetchUsers(params)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  async mounted() {
    this.setUserData();
  }
});
